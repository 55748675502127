import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { InputTextarea } from 'primereact/inputtextarea';
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import CommonService from '../../../services/common.service';
import axios from 'axios';
import EmailTemplateService from '../../../services/emailtemplate.service';


export default function EmailTemplateManagementCreate(){
    const navigate = useNavigate();

    const fileInputRef = useRef(null);
    const [error, setError] = useState({});
    const [title, setTitle] = useState(null);
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const {showSuccessMessage, showErrorMessage} = useToast();

    const [companyLogo, setCompanyLogo] = useState(null);

    const goBack = () => navigate(-1);
    const navigateTo = (path) => navigate(path);
    const fileRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            fileRef.current = file;
            const reader = new FileReader();
            reader.onload = () => setCompanyLogo(reader.result);
            reader.readAsDataURL(file);
        }
      };
    
      const handleButtonClick = () => fileInputRef.current.click();

    const validate = () => {
        setError({})
        const error = {};

        if(!title || title?.trim()?.length === 0)
            error['title'] = 'Title required';

        if(!template)
            error['template'] = 'Email template required';

        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const handleInput = (key, event) => {
        const value = event?.target?.value;
        if(key === "title")
            setTitle(value)
        else if(key === "template")
            setTemplate(value)
    }


    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setLoading(true);
            const requestData = { template, title };

            if(companyLogo && fileRef.current){
                const file = fileRef.current;
                const fileName = file && file?.['name'] ? file?.['name']: Date.now();
                const presignUrl = await CommonService.generatePresignUrl('company-logos', fileName);
                const {url} = presignUrl.data.data;

                await axios.put(url, file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                    }
                });
                requestData['companyLogo'] = url?.split('?sv=')[0];
            }

            const response = await EmailTemplateService.createTemplate(requestData);

            if(response.status === 201){
                setLoading(false);
                showSuccessMessage({ 
                    summary: 'Created Email Template', 
                    detail: 'Successfully created email template'
                })
                navigateTo('/dashboard/email-templates')
            }
        } catch (error) {
            setLoading(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to create email template'
            })
        }
    }
  
    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className={`${loading ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center`}>
                <div className='w-40'><LoadingComponent /></div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-primary">
                    <MdKeyboardArrowLeft 
                        size={26}
                        onClick={goBack}
                        className='cursor-pointer'
                    />
                    <h1 className='text-xl text-primary font-bold'>Create Email Template</h1>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <div className='flex gap-5 items-center'>
                    <div className="flex-col flex-1 flex gap-2">
                        <label
                            htmlFor="title" 
                            className="text-darkGray font-medium text-sm">
                            Title *
                        </label>
                        <InputText 
                            id="title"
                            value={title}
                            placeholder="Enter Title" 
                            onChange={handleInput.bind(this, 'title')}
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['title']) && (
                            <span className="text-dr font-normal text-xs">{error['title']}</span>     
                        )}
                    </div>
                    <div className="items-center flex-1 flex gap-2 justify-between">
                        <div className='flex flex-col gap-2'>
                            <label  
                                htmlFor="description"
                                className="text-darkGray font-medium text-xs">
                                Company Logo
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                ref={fileInputRef}
                                className="hidden"
                            />
                            <button
                                onClick={handleButtonClick} 
                                className='border-blue h-10 border-2 rounded-md px-5 text-blue text-xs'>
                                Upload Logo
                            </button>
                        </div>
                       {companyLogo && (
                            <img 
                                alt='company logo'
                                src={companyLogo}
                                className='h-20 w-32 rounded-md text-sm'
                            />
                       )}
                    </div>
                </div>
                <div className='flex justify-between gap-5'>
                    <div className='flex-col flex gap-2 w-full'>
                        <label
                            htmlFor="template" 
                            className="text-darkGray font-medium text-sm">
                            Template *
                        </label>
                        <InputTextarea
                            rows={36}
                            value={template} 
                            placeholder="Write or paste template"
                            onChange={handleInput.bind(this, 'template')}
                            className='border border-l_border text-sm p-2 font-normal'
                        />
                        {(error['template'] || template?.trim()?.length === 0) && 
                            <span className="text-dr font-normal text-xs">{error['template']}</span>
                        }
                    </div>
                    <div className='flex-col flex gap-2 w-full py-6'>
                        <div
                            className="flex-1 h-full"
                            dangerouslySetInnerHTML={{ __html: template }}
                        />
                    </div>
                </div>
            </div>
            <button 
                onClick={submit}
                className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5'>
                Create
            </button>
        </div>
    )
}