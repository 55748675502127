import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import EmailTemplateService from '../../../services/emailtemplate.service';
import CommonService from '../../../services/common.service';


export default function EmailTemplateManagementUpdate(){
    const {id} = useParams();
    const navigate = useNavigate();

    const fileInputRef = useRef(null);
    const fileRef = useRef(null);

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState({});
    const {showSuccessMessage, showErrorMessage} = useToast();
    const [initialEmailTemplate, setInitialEmailTemplate] = useState({});

    const [companyLogo, setCompanyLogo] = useState(null);

    const goBack = () => navigate(-1);
    const navigateTo = (path) => navigate(path);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            fileRef.current = file;
            const reader = new FileReader();
            reader.onload = () => setCompanyLogo(reader.result);
            reader.readAsDataURL(file);
        }
      };
    
      const handleButtonClick = () => fileInputRef.current.click();

    const validate = () => {
        setError({})
        const error = {};

        if(!emailTemplate?.['title'] || emailTemplate?.['title']?.trim()?.length === 0)
            error['title'] = 'Title required';

        if(!emailTemplate?.['template'])
            error['template'] = 'Email template required';

        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const handleInput = (key, event) => {
        const value = event?.target?.value;
        if(key === "title")
            setEmailTemplate({...emailTemplate || {}, title: value});
        else if(key === "template")
            setEmailTemplate({...emailTemplate || {}, template: value});
    }


    useEffect(() => {
        async function getTemplate(){
            try {
                setLoading(true);
                const response = await EmailTemplateService.getTemplate(id);
                setLoading(false);
                if(response.status === 200){
                    const {data} = response.data;
                    const template = data['template']?.replaceAll('&lt;', '<');
                    setEmailTemplate({...data, template});
                    setInitialEmailTemplate({...data, template});
                }
            } catch (error) {
                setLoading(false);
            }
        }
        getTemplate();
    }, []);

    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setIsUpdating(true);
            const requestData = {};

            if(initialEmailTemplate['title'] !== emailTemplate?.['title'])
                requestData['title'] = emailTemplate?.['title'];

            if(initialEmailTemplate['template'] !== emailTemplate?.['template'])
                requestData['template'] = emailTemplate?.['template'];

            if(companyLogo && fileRef.current){
                const file = fileRef.current;
                const fileName = file && file?.['name'] ? file?.['name']: Date.now();
                const presignUrl = await CommonService.generatePresignUrl('company-logos', fileName);
                const {url} = presignUrl.data.data;

                await axios.put(url, file, {headers: { 'x-ms-blob-type': 'BlockBlob' }});
                requestData['companyLogo'] = url?.split('?sv=')[0];
            }

            const response = await EmailTemplateService.updateTemplate(id, requestData);

            if(response.status === 200){
                setIsUpdating(false);
                showSuccessMessage({ 
                    summary: 'Updated Email Template', 
                    detail: 'Successfully updated email template'
                })
                navigateTo('/dashboard/email-templates')
            }
        } catch (error) {
            setIsUpdating(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to updated email template'
            })
        }
    }

    if(loading){
        return (
            <div className="flex flex-col min-h-full gap-4 p-5">
                <div className='h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center'>
                    <div className='w-40'>
                        <LoadingComponent />
                    </div>
                </div>
            </div>
        )
    }
  
    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className={`${isUpdating ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center`}>
                <div className='w-40'>
                    <LoadingComponent />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-primary">
                    <MdKeyboardArrowLeft 
                        size={26}
                        onClick={goBack}
                        className='cursor-pointer'
                    />
                    <h1 className='text-xl text-primary font-bold'>Update Email Template</h1>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <div className='flex gap-5 items-center'>
                    <div className="flex-col flex-1 flex gap-2">
                        <label
                            htmlFor="title" 
                            className="text-darkGray font-medium text-sm">
                            Title *
                        </label>
                        <InputText 
                            id="title"
                            value={emailTemplate?.['title']}
                            placeholder="Enter Title" 
                            onChange={handleInput.bind(this, 'title')}
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['title'] || (!emailTemplate?.['title'] || emailTemplate?.['title']?.trim()?.length === 0)) && (
                            <span className="text-dr font-normal text-xs">{error['title']}</span>     
                        )}
                    </div>
                    <div className="items-center flex-1 flex gap-2 justify-between">
                        <div className='flex flex-col gap-2'>
                            <label  
                                htmlFor="description"
                                className="text-darkGray font-medium text-xs">
                                Company Logo
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                ref={fileInputRef}
                                className="hidden"
                            />
                            <button
                                onClick={handleButtonClick} 
                                className='border-blue h-10 border-2 rounded-md px-5 text-blue text-xs'>
                                Upload Logo
                            </button>
                        </div>
                       {(companyLogo || emailTemplate?.['companyLogo']) && (
                            <img 
                                alt='company logo'
                                className='h-20 w-32 rounded-md text-sm'
                                src={companyLogo || emailTemplate?.['companyLogo']}
                            />
                       )}
                    </div>
                </div>
                <div className='flex justify-between gap-5'>
                    <div className='flex-col flex gap-2 w-full'>
                        <label
                            htmlFor="template" 
                            className="text-darkGray font-medium text-sm">
                            Template *
                        </label>
                        <InputTextarea
                            rows={36}
                            value={emailTemplate?.['template']} 
                            placeholder="Write or paste template"
                            onChange={handleInput.bind(this, 'template')}
                            className='border border-l_border text-sm p-2 font-normal'
                        />
                        {(error['template'] || emailTemplate?.['template']?.trim()?.length === 0) && 
                            <span className="text-dr font-normal text-xs">{error['template']}</span>
                        }
                    </div>
                    <div className='flex-col flex gap-2 w-full py-6'>
                        <div
                            className="flex-1 h-full"
                            dangerouslySetInnerHTML={{ __html: emailTemplate?.['template'] }}
                        />
                    </div>
                </div>
            </div>
            <button 
                onClick={submit}
                className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5'>
                Update
            </button>
        </div>
    )
}