import React from 'react';
import { useCallback } from 'react';
import { Column } from "primereact/column";
import { FcTimeline } from "react-icons/fc";
import { Tooltip } from 'primereact/tooltip';
import { MdInfoOutline } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { InputText } from 'primereact/inputtext';
import { AiOutlineUpload } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import JDService from "../../../services/jd.service";
import ConsoleHelper from '../../../utils/consoleHelper';
import { useLocation, useNavigate } from "react-router-dom";
import ResumeService from "../../../services/resume.service";
import { useAuth } from '../../../contextProviders/AuthContext';
import { useToast } from "../../../contextProviders/ToastContext";
import { useConfig } from '../../../contextProviders/ConfigContext';
import { RESUME_STAGES, RESUME_STATUS } from "../../../utils/constants";
import ResumeViewerPopup from '../../../components/JD/ResumeViewerPopup';
import CustomPaginator from '../../../components/Common/CustomPaginator';
import { usePageData } from '../../../contextProviders/PagesDataContext';
import AddResumeLessPopup from "../../../components/Resume/AddResumeLessPopup";
import ResumesUploadPopup from "../../../components/Resume/ResumesUploadPopup";
import ResumeSelectPopup from '../../../components/Resume/ResumesSelectPopup';
import {
    formatLabel,
    isValidEmail,
    parseToISTFromUTC,
    getPaginationLimit,
    capitalizeFirstLetterOfEachWord,
    convertUTCToLocal,
} from '../../../utils/common';
import TimelinePopup from '../../../components/Resume/ResumeActivitiesPopup';
import { contentSkeleton } from '../../../components/Common/TableLoadingComponents';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import { Dropdown } from 'primereact/dropdown';
import ShortlistAllResumePopup from '../../../components/Resume/ShortlistAllResumePopup';
import RejectCandidatesPopup from '../../../components/Resume/RejectCandidatesPopup';

const REFRESH_INTERVAL = 30000;

const JDDetailsResumes = React.memo((props) => {
    const { user } = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [search, setSearch] = useState(null)
    const intervalResumeInQueueRef = useRef(null);
    const intervalResumeShortListRef = useRef(null);

    const { updatePageData, getPageData } = usePageData();
    const jdPage = getPageData('jd-resumes') || {};

    const [resumeUrl, setResumeUrl] = useState(0);
    const [loading, setLoading] = useState(false);

    const [sort, setSort] = useState(jdPage['sort']);
    const [sortField, setSortField] = useState(jdPage['sortField']);
    const [limit, setLimit] = useState(jdPage?.['limit'] || 10);
    const [resumes, setResumes] = useState(jdPage?.['resumes'] || []);
    const [totalCount, setTotalCount] = useState(jdPage?.['totalCount'] || 0);
    const [totalPage, setTotalPage] = useState(jdPage?.['totalPage'] || 0);
    const [currentPage, setCurrentPage] = useState(jdPage?.['currentPage'] || 1);

    const { showErrorMessage, showSuccessMessage } = useToast();
    const [selectedResumes, setSelectedResumes] = useState([]);

    const jdId = pathname.replace('/dashboard/job-description/', '');

    const [showResumesSelectPopup, setShowResumesSelectPopup] = useState(false);
    const [showResumelessPopup, setShowResumelessPopup] = useState(false);
    const [showResumesUploadPopup, setShowResumesUploadPopup] = useState(false);
    const [showShortlistAllResumePopup, setShowShortlistAllResumePopup] = useState(false);
    const [showResumePopup, setShowResumePopup] = useState(false);
    const [timelineId, setTimelineId] = useState(null);
    const [showTimelinePopup, setShowTimelinePopup] = useState(false);
    const [showRejectResumePopup,setShowRejectResumePopup] = useState(false)
    const interviewStages = [
        { id: 1, key: 'videoProfiling', label: 'Send Video Profiling Invite', stage: RESUME_STAGES.VIDEO_PROFILING },
        { id: 2, key: 'hrScreening', label: 'Send Initial Invite', stage: RESUME_STAGES.INITIAL_SCREENING },
        { id: 3, key: 'bpoHrScreening', label: 'Send BPO HR Invite', stage: RESUME_STAGES.BPO_HR_SCREENING },
        { id: 4, key: 'technicalScreening', label: 'Send Technical Invite', stage: RESUME_STAGES.TECHNICAL_SCREENING },
        { id: 5, key: 'opsScreening', label: 'Send Ops Invite', stage: RESUME_STAGES.OPS_SCREENING }
    ];


    useEffect(() => {
        const handleResize = () => {
            setLimit(getPaginationLimit())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const refreshQueuedResumes = useCallback(async () => {
        try {
            const queuedResumeIds = resumes
                .filter(item => item?.status === RESUME_STATUS.PARSING)
                .map(item => item?._id);

            if (queuedResumeIds.length === 0) return;

            const response = await ResumeService.getResumesByIds(queuedResumeIds);
            if (response.status === 200) {
                const updatedResumes = response?.data?.data || [];
                setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.stage !== data?.stage;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));

                const hasQueuedResumes = updatedResumes.some(resume => resume?.status === RESUME_STATUS.PARSING);
                if (!hasQueuedResumes && intervalResumeInQueueRef.current) {
                    clearInterval(intervalResumeInQueueRef.current);
                    intervalResumeInQueueRef.current = null;
                    ConsoleHelper.log('All resumes processed. Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing queued resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const refreshShortlistInProgressResumes = useCallback(async () => {
        try {
            const resumeIds = resumes
                .filter(item => item?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS)
                .map(item => item?._id);

            if (resumeIds.length === 0) return;

            const response = await ResumeService.getResumesByIds(resumeIds);
            if (response.status === 200) {
                const updatedResumes = response?.data?.data || [];
                setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.status !== data?.status;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));

                const hasInProgressResumes = updatedResumes.some(resume => resume?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS);
                if (!hasInProgressResumes && intervalResumeShortListRef.current) {
                    clearInterval(intervalResumeShortListRef.current);
                    intervalResumeShortListRef.current = null;
                    ConsoleHelper.log('Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing in progress resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const getResumes = async (sort, sortField) => {
        try {
            setLoading(true);
            const response = await ResumeService.getJDResumes(jdId, currentPage, limit, sort, sortField, search);
            setLoading(false);
            if (response.status === 200) {
                const { data, totalCount, totalPage } = response.data || [];
                const list = data?.map((item) => ({ ...item, selected: false }))
                setResumes(list);
                setTotalCount(totalCount);
                setTotalPage(totalPage);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const searchResumes = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.getJDResumes(jdId, 1, 10, sort, sortField, search);
            setLoading(false);
            if (response.status === 200) {
                const { data, totalCount, totalPage } = response.data || [];
                const list = data?.map((item) => ({ ...item, selected: false }))
                setResumes(list);
                setTotalCount(totalCount);
                setTotalPage(totalPage);
            }
        } catch (error) {
            setLoading(false);
        }
    }


    useEffect(() => {
        getResumes(sort, sortField);
    }, [currentPage, limit]);

    useEffect(() => {
        intervalResumeInQueueRef.current = setInterval(refreshQueuedResumes, REFRESH_INTERVAL);
        return () => {
            if (intervalResumeInQueueRef.current) {
                clearInterval(intervalResumeInQueueRef.current);
            }
        };
    }, [refreshQueuedResumes]);

    useEffect(() => {
        intervalResumeShortListRef.current = setInterval(refreshShortlistInProgressResumes, REFRESH_INTERVAL);
        return () => {
            if (intervalResumeShortListRef.current) {
                clearInterval(intervalResumeShortListRef.current);
            }
        };
    }, [refreshShortlistInProgressResumes]);

    const reload = async () => {
        setShowResumesSelectPopup(false)
        await getResumes();
    }

    const onRowEditComplete = async (e) => {
        try {
            let data = [...resumes];
            let { newData, index } = e;
            const email = newData['email'];
            const name = newData['name'];

            const meta = { ...newData.meta };
            if (name) meta['Name'] = name;
            if (email) meta['Email'] = email;

            const requestData = {};
            if (name) requestData['name'] = name;
            if (email) requestData['email'] = email?.trim();
            if (Object.keys(requestData).length) {
                const response = await ResumeService.updateResume(newData?._id, requestData);
                if (response.status === 200) {
                    data[index] = { ...newData, meta: meta };
                    setResumes(data);
                    showSuccessMessage({ summary: 'Updated', detail: "Updated resume" })
                }
            }
        } catch (error) {
            showErrorMessage({ summary: "Failed", detail: 'Failed to update resume' })
        }
    };

    const sortlistSelectedResumes = async () => {
        try {
            if (selectedResumes.length === 0) {
                showErrorMessage({ summary: 'Required', detail: 'Please select resumes' });
                return;
            }

            const list = [];
            selectedResumes.forEach(resume => {
                if (resume?.meta['Email'] && isValidEmail(resume?.meta['Email']?.trim())) {
                    list.push(resume);
                }
            });
            if (list?.length === 0) return;

            const resumesForShortlisting = list?.filter((item) => item?.['resumeScreening']?.['status'] === RESUME_STATUS.NA)
            if (resumesForShortlisting?.length === 0) {
                showErrorMessage({ summary: 'Already Shortlisted', detail: 'All selected resumes are already shortlisted' });
                setSelectedResumes([]);
                return;
            }

            setLoading(true);
            const response = await ResumeService.sortlistResumes(jdId, {
                resumeIds: resumesForShortlisting?.map((item) => item?._id)
            });
            setLoading(false);
            if (response.status === 200) {
                await getResumes();
                setSelectedResumes([]);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: 'Failed', detail: error?.response?.data?.message })
        }
    }

    const sortlistAllResume = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.sortlistResumes(jdId, { sortlistAll: true });
            setLoading(false);
            if (response.status === 200) {
                await getResumes();
                showSuccessMessage({
                    summary: 'Success',
                    detail: 'Successfully shortlisted all resume!'
                })
                setSelectedResumes([]);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: 'Failed', detail: error?.response?.data?.message })
        }
    }

    const reparse = async (resumeId, event) => {
        try {
            event.stopPropagation();
            setLoading(true);
            const response = await ResumeService.reparseResume(resumeId);
            setLoading(false);
            if (response.status === 200) {
                const data = resumes?.map((item) => {
                    if (item['_id'] === resumeId) return { ...item, status: 'PARSING' }
                    return item;
                });
                setResumes(data);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const sendInterviewInviteAll = async (interviewType) => {
        try {
            if (selectedResumes.length === 0) {
                showErrorMessage({ summary: 'Required', detail: 'Please select resumes' });
                return;
            }
            const list = [];
            selectedResumes.forEach(resume => {
                if (resume?.meta['Email'] && isValidEmail(resume?.meta['Email']?.trim())) {
                    list.push(resume);
                }
            });
            if (list?.length === 0) return;

            setLoading(true);
            const response = await JDService.sendInterviewInvite(jdId, {
                interviewType: interviewType,
                resumeIds: list?.map((item) => item?._id)
            })
            setLoading(false);
            if (response?.status === 200) {
                let message = 'Interview Invite sent';
                if (interviewType === RESUME_STAGES.INITIAL_SCREENING || interviewType === RESUME_STAGES.BPO_HR_SCREENING)
                    message = 'Initial Interview invite sent';
                else if (interviewType === RESUME_STAGES.OPS_SCREENING)
                    message = 'OPS Interview invite sent';
                else if (interviewType === RESUME_STAGES.TECHNICAL_SCREENING)
                    message = 'Technical Interview invite sent';
                else if (interviewType === RESUME_STAGES.VIDEO_PROFILING)
                    message = 'Video Profiling invite sent';

                showSuccessMessage({
                    summary: 'Invites sent',
                    detail: message
                });
                setSelectedResumes([]);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: "Failed", detail: 'Failed to sent invite' })
        }
    }

    const rejectCandidates = async (rejectionReason) => {
      try {
        if (selectedResumes.length === 0) {
          showErrorMessage({
            summary: "Required",
            detail: "Please select resumes",
          });
          return;
        }
        const list = selectedResumes.map((resume) => resume._id);
        if (list?.length === 0) return;
        setLoading(true);
        const response = await JDService.rejectResumes({
          resumeIds: list,
          rejectionReason,
        });
        setLoading(false);
        if (response?.status === 200) {
          showSuccessMessage({
            summary: "Success",
            detail: "Resume Rejected successfully!",
          });
          setSelectedResumes([]);
          await getResumes();
        }
      } catch (error) {
        setLoading(false);
        showErrorMessage({
          summary: "Failed",
          detail: "Failed to reject resumes",
        });
      }
    };

    const allowEdit = (rowData) => {
        return true;
        // return rowData?.status !== RESUME_STAGES.PARSING;
    };

    const textEditor = (options) => {
        const data = options?.rowData?.meta;

        let defaultValue = null;
        if (options.field === 'name') {
            defaultValue = data?.['Name'];
        } else if (options.field === 'email') {
            defaultValue = data?.['Email'];
        }

        return (
            <InputText
                type="text"
                className='border border-primary px-1 h-10 max-w-40'
                onChange={(e) => options.editorCallback(e.target.value)}
                value={options?.value === undefined ? defaultValue : options?.value}
            />
        );
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const renderEmail = ({ meta, _id }) => {
        const dynamicClass = `tooltip-email-${_id}`;
        const email = meta?.['Email'] || meta?.['email'];

        if (email) {
            return (
                <div className="flex gap-4 items-center w-32" onClick={handleCopy.bind(this, email)}>
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <h3 className='text-xs'>{email}</h3>
                            <span className='text-xs text-blue'>Click on email to copy</span>
                        </div>
                    </Tooltip>
                    <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>{email}</span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    -
                </span>
            </div>
        )
    }

    const renderName = ({ meta, _id }) => {

        const dynamicClass = `tooltip-name-${_id}`;
        const name = meta?.['Name'] || meta?.['name'];

        if (name) {
            return (
                <div className="flex gap-4 items-center" onClick={handleCopy.bind(this, name)}>
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <h3 className='text-xs'>{capitalizeFirstLetterOfEachWord(name)}</h3>
                        </div>
                    </Tooltip>
                    <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>
                        {capitalizeFirstLetterOfEachWord(name)}
                    </span>
                </div>
            )
        }
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    -
                </span>
            </div>
        )
    }

    const renderStage = ({ stage }) => {

        return (
            <div className="flex gap-4 items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap font-medium cursor-pointer'>
                    {formatLabel(stage)}
                </span>
            </div>
        )
    }

    function isInviteExpired(inviteTimeUTC) {
        const inviteTime = new Date(inviteTimeUTC);
        const currentTime = new Date();
        if (inviteTime < currentTime) return true
        return false
    }

    const getStatus = (props) => {
        const { initialScreening, videoProfiling, stage, status, technicalScreening, opsScreening, resumeScreening } = props;

        if (
            stage === RESUME_STAGES.RESUME_SCREENING &&
            (status === RESUME_STATUS.FAILED || resumeScreening?.status === RESUME_STATUS.FAILED)
        ) return 'RETRY';

        switch (stage) {
            case RESUME_STAGES.INITIAL_SCREENING:
                return isInviteExpired(initialScreening?.inviteExpireAt) ? 'Invite Expired' : initialScreening?.status;
            case RESUME_STAGES.BPO_HR_SCREENING:
                return isInviteExpired(initialScreening?.inviteExpireAt) ? 'Invite Expired' : initialScreening?.status;
            case RESUME_STAGES.TECHNICAL_SCREENING:
                return isInviteExpired(technicalScreening?.inviteExpireAt) ? 'Invite Expired' : technicalScreening?.status;
            case RESUME_STAGES.OPS_SCREENING:
                return isInviteExpired(opsScreening?.inviteExpireAt) ? 'Invite Expired' : opsScreening?.status;
            case RESUME_STAGES.VIDEO_PROFILING:
                return isInviteExpired(videoProfiling?.inviteExpireAt) ? 'Invite Expired' : videoProfiling?.status;
            default: return status;
        }
    };


    const renderStatus = (props) => {

        const dynamicClass = `tooltip-name-${props._id}`;

        if (props?.['status'] === 'NO_CONTENT_FOUND' || props?.['status'] === 'INVALID_FILE_CONTENT') {
            return (
                <div className="flex gap-4 items-center w-28">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <p className='text-xs'>
                                Update the resume content and/or format and upload again.
                            </p>
                        </div>
                    </Tooltip>
                    <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                        Fix Resume
                    </span>
                    <MdInfoOutline
                        size={32}
                        className={`${dynamicClass} cursor-pointer text-blue`}
                    />
                </div>
            )
        }
        else if (props?.['status'] === 'DUPLICATE') {
            return (
                <div className="flex gap-4 items-center w-28">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <p className='text-xs'>
                                It seems this resume already exists or is a duplicate.
                            </p>
                        </div>
                    </Tooltip>
                    <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                        Duplicate
                    </span>
                    <MdInfoOutline
                        size={32}
                        className={`${dynamicClass} cursor-pointer text-blue`}
                    />
                </div>
            )
        }

        else if (props?.['status'] === 'RETRY') {
            return (
                <div className="flex gap-4 items-center w-28">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <p className='text-xs'>
                                Please retry parsing the user information.
                            </p>
                        </div>
                    </Tooltip>
                    <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                        Retry
                    </span>
                    <MdInfoOutline
                        size={32}
                        className={`${dynamicClass} cursor-pointer text-blue`}
                    />
                </div>
            )
        }
     

        else if (props?.['status'] === 'REJECTED') {
            return (
                <div className="flex gap-4 items-center w-28">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <p className='text-xs'>
                                {props?.rejectionReason}
                            </p>
                        </div>
                    </Tooltip>
                    <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                        Rejected
                    </span>
                    <MdInfoOutline
                        size={32}
                        className={`${dynamicClass} cursor-pointer text-blue`}
                    />
                </div>
            )
        }

        else if (props?.['status'] === 'NA' && props?.['meta']?.['Email']) {
            return (
                <div className="flex gap-4 items-center w-28">
                    <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                        Completed
                    </span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                    {formatLabel(getStatus(props))}
                </span>
            </div>
        )
    }

    const lastUpdated = (props) => {
        return (
            <div className="flex gap-4 text-start p-0">
                <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                    {/* {convertUTCToLocal(props?.updatedAt)} */}
                    {getLastUpdateAt(props)}
                </span>
            </div>
        )
    }

    const showResume = (item) => {
        if (item['resumeUrl'])
            setResumeUrl(item['resumeUrl']);
        setShowResumePopup(true);
    }

    const showTimeline = (item) => {
        setTimelineId(item['_id']);
        setShowTimelinePopup(true);
    }

    const viewResume = (item) => {
        return (
            <div
                onClick={(event) => event?.stopPropagation()}
                className="flex gap-4 items-center">
                <CgFileDocument
                    size={20}
                    onClick={showResume.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
            </div>
        )
    }

    const viewActivities = (item) => {
        const dynamicClass = `tooltip-timeline-${item?._id}`;
        return (
            <div
                onClick={(event) => event?.stopPropagation()}
                className={`flex gap-4 items-center ${dynamicClass}`}>
                <FcTimeline
                    size={20}
                    onClick={showTimeline.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
                <Tooltip target={`.${dynamicClass}`} position="bottom">
                    <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                        <span className='text-xs'>Activities</span>
                    </div>
                </Tooltip>
            </div>
        )
    }

    const renderUploadedBy = ({ userDetails }) => {
        return (
            <div className="flex gap-4 text-sm items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {!userDetails ? '-' : user?.['_id'] === userDetails?.['_id'] ? 'Self' : (userDetails?.['firstName'])}
                </span>
            </div>
        )
    }

    const renderVideoProfilingScore = (props) => {
        const { videoProfiling, status, stage } = props;
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || videoProfiling?.['score'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(videoProfiling?.['score'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    };

    const renderTechnicalScreeningScore = (props) => {
        const { technicalScreening, stage, status } = props;
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || technicalScreening?.['score'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(technicalScreening?.['score'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    };

    const renderOpsScreeningScore = (props) => {
        const { opsScreening, stage, status } = props;
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || opsScreening?.['score'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(opsScreening?.['score'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    };

    const renderScore = (props) => {
        const { resumeScreening, stage, status } = props;
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || resumeScreening?.meta?.['score'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(resumeScreening?.meta?.['score'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    };

    const experienceScore = (props) => {
        const { resumeScreening, stage, status } = props;
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || resumeScreening?.meta?.['score'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(resumeScreening?.meta?.['scoreInfo']?.['relevant_experience'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    };

    const renderSkillScore = ({ resumeScreening, stage, status }) => {
        if (stage === RESUME_STAGES.RESUME_RECEIVED || status === RESUME_STATUS.PARSING || resumeScreening?.['meta']?.['scoreInfo']?.['relevant_skill'] === undefined)
            return (<div className="flex items-center justify-center"><span>-</span></div>)

        const score = parseFloat(resumeScreening?.['meta']?.['scoreInfo']?.['relevant_skill'] || 0).toFixed(1);

        function getTheme() {
            if (score >= 80) return 'bg-lg text-dg';
            else if (score >= 50) return 'bg-lo text-do';
            else return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center justify-center">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{score}%</span>
                </div>
            </div>
        )
    }

    const getScreening = (interviewType, data) => {
        const videoProfiling = data?.['videoProfiling'] || {};
        const initialScreening = data?.['initialScreening'] || {};
        const opsScreening = data?.['opsScreening'] || {};
        const resumeScreening = data?.['resumeScreening'] || {};
        const technicalScreening = data?.['technicalScreening'] || {};

        switch (interviewType) {
            case RESUME_STAGES.RESUME_SCREENING:
                return resumeScreening;
            case RESUME_STAGES.OPS_SCREENING:
                return opsScreening;
            case RESUME_STAGES.TECHNICAL_SCREENING:
                return technicalScreening;
            case RESUME_STAGES.INITIAL_SCREENING:
                return initialScreening;
            case RESUME_STAGES.VIDEO_PROFILING:
                return videoProfiling;
            default: return {}
        }

    }

    const getLastUpdateAt = (data) => {
        let events = [];
        if (data?.['resumeParsing']?.events && data?.['resumeParsing']?.events?.length > 0)
            events.push(...data?.['resumeParsing']?.['events'])
        if (data?.['opsScreening']?.events && data?.['opsScreening']?.events?.length > 0)
            events.push(...data?.['opsScreening']?.['events'])
        if (data?.['initialScreening']?.events && data?.['initialScreening']?.events?.length > 0)
            events.push(...data?.['initialScreening']?.['events'])
        if (data?.['technicalScreening']?.events && data?.['technicalScreening']?.events?.length > 0)
            events.push(...data?.['technicalScreening']?.['events'])
        if (data?.['videoProfiling']?.events && data?.['videoProfiling']?.events?.length > 0)
            events.push(...data?.['videoProfiling']?.['events'])
        if (data?.['resumeScreening']?.events && data?.['resumeScreening']?.events?.length > 0)
            events.push(...data?.['resumeScreening']?.['events'])


        if (!events || events?.length === 0)
            return '-'

        events = events.reverse();

        const timestamp = events?.filter((item) => (item['type'] === data?.stage && item['name'] === data['status']))?.[0]?.timestamp;
        return timestamp ? convertUTCToLocal(timestamp) : '-';
    }

    const rowExpansionTemplate = (data) => {

        const getInterviewEndTime = (interviewType) => {
            if (data?.status === RESUME_STATUS.INVITE_SENT) return 'NA';

            const object = getScreening(interviewType, data);
            const events = object?.events?.reverse()?.filter((item) => item['type'] === interviewType);
            const timestamp = events?.filter((item) => item['name'] === 'END')[0]?.timestamp;
            return timestamp ? parseToISTFromUTC(timestamp) : 'NA';
        }

        const getInterviewStartTime = (interviewType) => {
            if (data?.status === RESUME_STATUS.INVITE_SENT) return 'NA';

            const object = getScreening(interviewType, data);
            const events = object?.events?.reverse()?.filter((item) => item['type'] === interviewType);
            const timestamp = events?.filter((item) => item['name'] === 'START')[0]?.timestamp;
            return timestamp ? parseToISTFromUTC(timestamp) : 'NA';
        }

        const getInviteSentTime = (interviewType) => {
            const object = getScreening(interviewType, data);
            const events = object?.events?.reverse()?.filter((item) => item['type'] === interviewType);
            const timestamp = events?.filter((item) => item['name'] === 'INVITE_SENT')[0]?.timestamp;
            return timestamp ? parseToISTFromUTC(timestamp) : 'NA';
        }

        const getCurrentStatus = (interviewType) => {
            const object = getScreening(interviewType, data);
            return object?.['status'] || 'NA';
        }

        return (
            <div className="flex gap-7 justify-center">
                <div className={`flex items-start flex-1 flex-col gap-2 ${config?.enabledServices?.['videoProfiling'] ? 'visible' : 'hidden'}`}>
                    <h2 className='text-sm font-semibold text-do'>Video Profiling</h2>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Current Status:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getCurrentStatus(RESUME_STAGES.VIDEO_PROFILING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Invite Sent Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInviteSentTime(RESUME_STAGES.VIDEO_PROFILING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Start Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewStartTime(RESUME_STAGES.VIDEO_PROFILING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>End Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewEndTime(RESUME_STAGES.VIDEO_PROFILING))}</span>
                        </div>
                    </div>
                </div>
                <div className={`flex items-start flex-1 flex-col gap-2 ${config?.enabledServices?.['resumeScreening'] ? 'visible' : 'hidden'}`}>
                    <h2 className='text-sm font-semibold text-do'>Resume Screening</h2>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Current Status:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getCurrentStatus(RESUME_STAGES.RESUME_SCREENING))}</span>
                        </div>
                        {/* <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Invite Sent Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInviteSentTime(RESUME_STAGES.RESUME_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Start Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewStartTime(RESUME_STAGES.RESUME_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>End Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewEndTime(RESUME_STAGES.RESUME_SCREENING))}</span>
                        </div> */}
                    </div>
                </div>
                <div className={`flex items-start flex-1 flex-col gap-2 ${config?.enabledServices?.['hrScreening'] || config?.enabledServices?.['bpoHrScreening'] ? 'visible' : 'hidden'}`}>
                    <h2 className='text-sm font-semibold text-do'>Initial Screening</h2>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Current Status:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getCurrentStatus(RESUME_STAGES.INITIAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Invite Sent Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInviteSentTime(RESUME_STAGES.INITIAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Start Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewStartTime(RESUME_STAGES.INITIAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>End Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewEndTime(RESUME_STAGES.INITIAL_SCREENING))}</span>
                        </div>
                    </div>
                </div>
                <div className={`flex items-start flex-1 flex-col gap-2 ${(config?.enabledServices?.['technicalScreening']) ? 'visible' : 'hidden'}`}>
                    <h2 className='text-sm font-semibold text-do'>Technical Screening</h2>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Current Status:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getCurrentStatus(RESUME_STAGES.TECHNICAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Invite Sent Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInviteSentTime(RESUME_STAGES.TECHNICAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Start Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewStartTime(RESUME_STAGES.TECHNICAL_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>End Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewEndTime(RESUME_STAGES.TECHNICAL_SCREENING))}</span>
                        </div>
                    </div>
                </div>
                <div className={`flex items-center flex-1 ${(config?.enabledServices?.['opsScreening']) ? 'visible' : 'hidden'}`}>
                    <h2 className='text-sm font-semibold text-do'>Ops Screening</h2>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Current Status:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getCurrentStatus(RESUME_STAGES.OPS_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Invite Sent Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInviteSentTime(RESUME_STAGES.OPS_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>Start Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewStartTime(RESUME_STAGES.OPS_SCREENING))}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <h4 className='font-medium text-sm'>End Time:</h4>
                            <span className='text-sm'>{capitalizeFirstLetterOfEachWord(getInterviewEndTime(RESUME_STAGES.OPS_SCREENING))}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section>
            {showResumePopup && (
                <ResumeViewerPopup
                    header="Resume"
                    resumeUrl={resumeUrl}
                    close={() => setShowResumePopup(false)}
                />
            )}
            {showResumesSelectPopup && (
                <ResumeSelectPopup
                    reload={reload}
                    hide={() => setShowResumesSelectPopup(false)}
                />
            )}
            {showResumelessPopup && (
                <AddResumeLessPopup hide={() => setShowResumelessPopup(false)} />
            )}
            {showResumesUploadPopup && (
                <ResumesUploadPopup
                    getResumes={getResumes}
                    hide={() => setShowResumesUploadPopup(false)}
                />
            )}
            {showTimelinePopup && (
                <TimelinePopup
                    timelineId={timelineId}
                    hide={() => setShowTimelinePopup(false)}
                />
            )}

            {showShortlistAllResumePopup && (
                <ShortlistAllResumePopup
                    sortlistAllResume={sortlistAllResume}
                    hide={() => setShowShortlistAllResumePopup(false)}
                />
            )}
            {showRejectResumePopup && (
                <RejectCandidatesPopup
                    rejectCandidates={rejectCandidates}
                    hide={() => setShowRejectResumePopup(false)}
                />
            )}
            <div
                className={`flex-1 flex items-center justify-between text-brownGray mb-5 ${props.jd?.active === false || (user && user["role"] === "admin")
                        ? "hidden"
                        : "visible"
                    }`}
            >
                <div className="flex gap-2">
                    <button
                        onClick={() => setShowResumesSelectPopup(true)}
                        className="bg-white h-8 hover:bg-primary hover:text-white justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer"
                    >
                        <span className="text-xs font-semibold">Select Resumes</span>
                        {/* <MdRefresh size={20} /> */}
                    </button>
                    <button
                        onClick={() => setShowResumesUploadPopup(true)}
                        className="bg-white h-8 justify-center flex items-center hover:bg-primary hover:text-white px-4 rounded-md gap-3 cursor-pointer"
                    >
                        <span className="text-xs font-semibold">Upload Resume</span>
                        <AiOutlineUpload size={16} />
                    </button>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={sortlistSelectedResumes}
                        className="bg-white h-8 justify-center flex items-center hover:bg-primary hover:text-white px-4 rounded-md gap-3 cursor-pointer add-resume"
                    >
                        <span className="text-xs font-semibold">
                            Shortlist Selected Resumes
                        </span>
                    </button>

                    <button
                        onClick={()=> setShowShortlistAllResumePopup(true)}
                        className="bg-white h-8 justify-center flex items-center hover:bg-primary hover:text-white px-4 rounded-md gap-3 cursor-pointer add-resume"
                    >
                        <span className="text-xs font-semibold">
                            Shortlist All Resumes
                        </span>
                    </button>
                    
                    <Dropdown 
                       className='bg-white h-8 justify-center flex items-center hover:text-white rounded-md  cursor-pointer add-resume'
                       placeholder={ <span className="text-xs font-semibold flex items-center">Send Interview Invites</span> }
                       options={interviewStages?.filter(({ key }) => config?.enabledServices?.[key])?.map(({ id, label, stage }) => 
                            ({
                                id,
                                label: <span className="text-xs font-semibold">{label}</span>,
                                value: stage,
                            }))}
                       onChange={(selectedOption) => sendInterviewInviteAll(selectedOption.value)}
                    />

                        <button
                            onClick={() => setShowRejectResumePopup(true)}
                            className="bg-white h-8 justify-center flex items-center hover:bg-primary hover:text-white px-4 rounded-md gap-3 cursor-pointer add-resume">
                            <span className="text-xs font-semibold">Reject Resumes</span>
                        </button>
                </div>
            </div>
            <div className="bg-white rounded">
                <div className="p-5 flex gap-5">
                    <InputText
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        className="h-8 border border-l_border px-2 text-sm w-1/3"
                        placeholder="Search by name or email"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                searchResumes();
                            }
                        }}
                    />
                    <button
                        onClick={searchResumes}
                        className="bg-primary text-white px-7 rounded-md"
                    >
                        Search
                    </button>
                </div>
                <DataTable
                    rows={200}
                    dataKey="_id"
                    editMode="row"
                    value={resumes}
                    selectionMode="checkbox"
                    className="text-brownGray"
                    selection={selectedResumes}
                    onSort={(props) => {
                        let sortValue = sort === "asc" ? "desc" : "asc";
                        setSort(sortValue);
                        setSortField(props.sortField);
                        getResumes(sortValue, props.sortField);
                    }}
                    emptyMessage={
                        loading ? <LoadingComponent /> : "No resumes available"
                    }
                    onRowEditComplete={onRowEditComplete}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onSelectionChange={(e) => setSelectedResumes(e.value)}
                    rowClassName="bg-white hover:text-blue hover:cursor-pointer"
                    onRowClick={({ data }) => {
                        const stage = data?.["stage"];
                        const status = data?.["status"];

                        if (
                            status === RESUME_STATUS.PARSING ||
                            stage === RESUME_STAGES.RESUME_RECEIVED
                        )
                            return;

                        const props = {
                            sort,
                            limit,
                            sortField,
                            jds: data,
                            totalCount,
                            currentPage,
                        };
                        updatePageData("jd-resumes", props);
                        navigate(`/dashboard/reports/${data?.["_id"]}`, {
                            state: { resumeUrl: data?.resumeUrl },
                        });
                    }}
                >
                    {!loading && (
                        <Column
                            selectionMode="multiple"
                            className=""
                            headerClassName="max-w-5 bg-white"
                            style={{
                                "--checkbox-size": "10px",
                            }}
                        />
                    )}
                    <Column
                        body={loading ? contentSkeleton() : viewResume}
                        className="w-10 p-0 pl-3"
                        headerClassName="text-sm bg-white"
                    />
                    <Column
                        body={loading ? contentSkeleton() : viewActivities}
                        headerClassName="text-sm bg-white"
                    />
                    <Column
                        sortable
                        header="Name"
                        field="name"
                        sortField="name"
                        body={loading ? contentSkeleton() : renderName}
                        className="text-sm w-1/10"
                        headerClassName="text-sm bg-white"
                        editor={(options) => textEditor(options)}
                    />
                    <Column
                        sortable
                        field="email"
                        header="Email"
                        sortField="email"
                        body={loading ? contentSkeleton() : renderEmail}
                        className="text-sm w-1/10"
                        headerClassName="text-sm bg-white"
                        editor={(options) => textEditor(options)}
                    />
                    {/* <Column 
                        header="Mobile number" 
                        body={renderMobileNumber}
                        className='text-sm'
                        headerClassName='text-sm bg-white' 
                    /> */}
                    <Column
                        sortable
                        sortField="score"
                        body={loading ? contentSkeleton() : renderScore}
                        header="Resume Score"
                        className="text-sm text-center w-1/2"
                        headerClassName="text-sm text-cente bg-white"
                    />
                    <Column
                        sortable
                        sortField="expScore"
                        body={loading ? contentSkeleton() : experienceScore}
                        header="Exp Score"
                        className="text-sm text-center w-1/2"
                        headerClassName="text-sm text-cente bg-white"
                    />
                    <Column
                        sortable
                        header="Skill Score"
                        sortField="skillScore"
                        body={loading ? contentSkeleton() : renderSkillScore}
                        className="text-sm text-center w-1/2"
                        headerClassName="text-sm text-cente bg-white"
                    />
                    {config?.enabledServices?.["videoProfiling"] && (
                        <Column
                            sortable
                            sortField="VideoProfilingScore"
                            header="Video Profiling"
                            body={loading ? contentSkeleton() : renderVideoProfilingScore}
                            className="text-sm text-center"
                            headerClassName="text-sm text-cente bg-white"
                        />
                    )}
                    {/* {(config?.enabledServices?.['hrScreening'] || config?.enabledServices?.['bpoHrScreening']) && (
                        <Column
                            header='Hr Screening'
                            body={renderHrScreeningScore} 
                            className='text-sm text-center'
                            headerClassName='text-sm text-cente bg-white w-1/2' 
                        />
                    )} */}
                    {config?.enabledServices?.["technicalScreening"] && (
                        <Column
                            sortable
                            sortField="technicalScore"
                            header="Technical Screening"
                            className="text-sm text-center"
                            body={
                                loading ? contentSkeleton() : renderTechnicalScreeningScore
                            }
                            headerClassName="text-sm text-center bg-white"
                        />
                    )}
                    {config?.enabledServices?.["opsScreening"] && (
                        <Column
                            header="Ops Screening"
                            body={loading ? contentSkeleton() : renderOpsScreeningScore}
                            className="text-sm text-center"
                            headerClassName="text-sm text-cente bg-white"
                        />
                    )}
                    {user?.["role"] === "hrAdmin" && (
                        <Column
                            sortable
                            sortField="hrName"
                            header="Uploaded By"
                            body={loading ? contentSkeleton() : renderUploadedBy}
                            className="text-sm text-center"
                            headerClassName="text-sm bg-white text-center"
                        />
                    )}

                    <Column
                        sortable
                        header="Stage"
                        sortField="stage"
                        body={loading ? contentSkeleton() : renderStage}
                        className="text-sm w-1/10"
                        headerClassName="text-sm bg-white"
                    />
                    <Column
                        sortable
                        sortField="status"
                        field="status"
                        header="Status"
                        body={loading ? contentSkeleton() : renderStatus}
                        className="text-sm text-center w-1/10"
                        headerClassName="text-sm bg-white p-0"
                    />
                    <Column
                        // sortable
                        // sortField='lastUpdated'
                        field="lastUpdated"
                        header="Last Updated"
                        body={loading ? contentSkeleton() : lastUpdated}
                        className="text-sm w-1/10 p-0"
                        headerClassName="text-sm bg-white p-0"
                    />
                    {/* <Column 
                        body={options}
                        className='text-sm'
                        headerClassName='text-sm bg-white' 
                    /> */}
                    {user && user["role"] !== "admin" && !loading && (
                        <Column
                            rowEditor={allowEdit}
                            className="text-sm w-1/10"
                            headerClassName="text-sm bg-white"
                        ></Column>
                    )}
                </DataTable>
            </div>
            {totalCount > 9 && (
                <CustomPaginator
                    limit={limit}
                    defaultRows={10}
                    totalRecords={resumes}
                    setLimit={setLimit}
                    totalPages={totalPage}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </section>
    );
});

export default JDDetailsResumes;