import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import addIcon from './../../../assets/icons/add.svg';
import { TbTemplate } from "react-icons/tb";
import EmailTemplateService from '../../../services/emailtemplate.service';
import LoadingComponent from '../../../components/Common/LoadingComponent';

export default function EmailTemplateManagement(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [emailTemplates, setEmailTemplates] = useState([]);

    const navigateTo = (path) => {
        navigate(path);
    }
    
    useEffect(() => {
        async function getTemplates(){
            try {
                setLoading(true);
                const response = await EmailTemplateService.getTemplates();
                setLoading(false);
                if(response.status === 200){
                    const {data} = response.data;
                    const sortedList = data.sort((template1, template2) => template1.title.localeCompare(template2.title));
                    setEmailTemplates(sortedList)
                }
            } catch (error) {
                setLoading(false);
            }
        }
        getTemplates();
    }, []);

    if(loading){
        return (
            <div className="flex flex-col min-h-full gap-4 p-5">
                <div className='h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center'>
                    <div className='w-40'><LoadingComponent /></div>
                </div>
            </div>
        )
    }

    return (
        <div 
            className="flex flex-col h-full gap-4 p-5">
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>Email Template</h1>
                <div 
                    onClick={navigate.bind(this, '/dashboard/email-templates/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Create Email Template</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            <div className={`flex justify-center items-center ${emailTemplates.length === 0 ? 'visible': 'hidden'}`}>
                <span>No email templates available</span>
            </div>
            <div className={`mt-2 p-2 flex flex-wrap gap-10 ${emailTemplates.length > 0 ? 'visible': 'hidden'}`}>
                {emailTemplates && emailTemplates.length > 0 && emailTemplates.map((item) => (
                    <div 
                        onClick={navigateTo.bind(this, `/dashboard/email-templates/${item['_id']}`)}
                        className='flex w-1/5 flex-col hover:scale-105 rounded-md gap-5 items-center cursor-pointer bg-white shadow-md drop-shadow-md p-2'>
                        {item?.companyLogo && (
                            <img 
                                alt='Company Logo'
                                src={item?.companyLogo}
                                className='h-28 w-60'
                            />
                        )}
                        {!item?.companyLogo && <TbTemplate className='h-32 w-80 text-blue' />}
                        <h2 className='text-base font-normal text-center px-5'>{item?.['title']}</h2>
                    </div>
                ))}
            </div>
        </div>
    )
}