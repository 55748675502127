import { FaRegEdit } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import EmailTemplateService from '../../../services/emailtemplate.service';

export default function EmailTemplateManagementView(){

    const {id} = useParams();
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const navigateTo = (path) => navigate(path);

    const [loading, setLoading] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState(null);

    useEffect(() => {
        async function getTemplate(){
            try {
                setLoading(true);
                const response = await EmailTemplateService.getTemplate(id);
                setLoading(false);
                if(response.status === 200){
                    const {data} = response.data;
                    const template = data['template']?.replaceAll('&lt;', '<');
                    setEmailTemplate({...data, template});
                }
            } catch (error) {
                setLoading(false);
            }
        }
        getTemplate();
    }, []);

    if(loading){
        return (
            <div className="flex flex-col min-h-full gap-4 p-5">
                <div className='h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center'>
                    <div className='w-40'><LoadingComponent /></div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-primary">
                    <MdKeyboardArrowLeft  
                        size={26}
                        onClick={goBack}
                        className="cursor-pointer" 
                    />
                    <div className="flex items-center gap-3">
                        <h1 className='text-xl text-primary font-bold'>{emailTemplate?.['title']}</h1>
                        <div className="bg-blue h-6 rounded-md flex justify-center items-center px-2">
                            <span className="text-white text-xs font-semibold">View Only</span>
                        </div>
                    </div>
                </div>
                <FaRegEdit 
                    size={26}
                    className="text-blue cursor-pointer"
                    onClick={navigateTo.bind(this, `/dashboard/email-templates/${id}/update`)}
                />
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <div className='flex gap-10 items-center'>
                    <div className="flex-col w-1/2 flex gap-2">
                        <label
                            htmlFor="title" 
                            className="text-darkGray font-medium text-sm">
                            Title *
                        </label>
                        <h3 className="border-l_border border-[1px] h-10 p-2 text-sm font-normal">
                            {emailTemplate?.['title']}
                        </h3>
                    </div>
                    {emailTemplate?.['companyLogo'] && (
                        <div className="items-center w-1/2 flex gap-2 justify-between">
                            <img 
                                alt='company logo'
                                src={emailTemplate?.['companyLogo']}
                                className='h-20 w-32 rounded-md'
                            />
                        </div>
                    )}
                </div>
                <div className='flex justify-between gap-5'>
                    <div className='flex-col flex gap-2 w-full'>
                        <label
                            htmlFor="template" 
                            className="text-darkGray font-medium text-sm">
                            Template *
                        </label>
                        <InputTextarea
                            rows={36}
                            readOnly={true}
                            value={emailTemplate?.['template']} 
                            className='border border-l_border text-sm p-2 font-normal'
                        />
                    </div>
                    <div className='flex-col flex gap-2 w-full py-6'>
                        <div
                            className="flex-1 h-full"
                            dangerouslySetInnerHTML={{ __html: emailTemplate?.['template'] }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}