import { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ReportInitialScreening from "./ReportInitalScreening";
import ReportResumeScreening from "./ReportResumeScreening";
import ReportTechnicalScreening from "./ReportTechnicalScreening";
import ReportOpsScreening from './ReportOpsScreening';
import ReportVideoProfiling from './ReportVideoProfiling';
import { useConfig } from '../../contextProviders/ConfigContext';
import ReportBpoHrScreening from './ReportBpoHrScreening';
import { RESUME_STAGES } from '../../utils/constants';
import {BounceLoader} from 'react-spinners';
import ViewResumePage from '../../components/Common/ViewResumePage';


export default function DetailsReport({latest_screening_type, report}){
    const config = useConfig();
    const [currentTabIndex, setCurrentTabIndex] = useState(-1);

    useEffect(() => {
        let index = 0;
        filteredTabs.forEach((tab) => {
            if(tab?.type === latest_screening_type){
                setCurrentTabIndex(index);
                return;
            }
            index += 1;
        });
        if(filteredTabs?.length === index)
            setCurrentTabIndex(0);
    }, [])

    const tabsConfig = [
        {
            key: "resumeScreening",
            header: "Resume Screening",
            type: RESUME_STAGES.RESUME_SCREENING,
            component: <ReportResumeScreening report={report}/>,
            condition: config?.enabledServices?.['resumeScreening'],
        },
        {
            key: "videoProfiling",
            header: "Video Profiling",
            type: RESUME_STAGES.VIDEO_PROFILING,
            component: <ReportVideoProfiling report={report} />,
            condition: config?.enabledServices?.['videoProfiling'],
        },
        {
            key: "initialScreening",
            header: "Initial Screening",
            type: RESUME_STAGES.INITIAL_SCREENING,
            component: <ReportInitialScreening report={report}/>,
            condition: config?.enabledServices?.['hrScreening']
        },
        {
            key: "initialScreening",
            header: "Bpo HR Screening",
            type: RESUME_STAGES.BPO_HR_SCREENING,
            component: <ReportBpoHrScreening report={report}/>,
            condition: config?.enabledServices?.['bpoHrScreening'],
        },
        {
            key: "technicalScreening",
            header: "Technical Screening",
            type: RESUME_STAGES.TECHNICAL_SCREENING,
            component: <ReportTechnicalScreening report={report}/>,
            condition: config?.enabledServices?.['technicalScreening'],
        },
        {
            key: "opsScreening",
            header: "Ops Screening",
            type: RESUME_STAGES.OPS_SCREENING,
            component: <ReportOpsScreening report={report}/>,
            condition: config?.enabledServices?.['opsScreening'],
        }
    ];

    const filteredTabs = tabsConfig.filter(tab => tab.condition);

    if(currentTabIndex === -1){
        return (
            <div className="justify-center items-center flex">
                <BounceLoader size={40} color="#2B4360"/>
            </div>
        )
    }

    return (
        <>
         <div className="px-5 bg-white rounded-md">
        <TabView
          className="text-sm"
          activeIndex={currentTabIndex}
          pt={{ inkbar: { className: "bg-white" } }}
          onBeforeTabChange={(event) => setCurrentTabIndex(event.index)}
        >
          {filteredTabs.map((tab, index) => (
            <TabPanel
              key={tab.key}
              header={tab.header}
              contentClassName="p-5"
              pt={{
                headerTitle: {
                  className: `${
                    currentTabIndex === index
                      ? "bg-blue text-white"
                      : "bg-[#F6F7F9] text-brownGray"
                  } py-2 px-4 rounded-md text-sm font-normal`,
                },
              }}
            >
              {tab.component}
            </TabPanel>
          ))}
        </TabView>
      </div></>
     
    );
}