import axios from "axios";
import axiosInstance from ".";
import { getDateRange } from "../utils/common";

async function generatePresignUrlForResume(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=resumes`)
}

async function generatePresignUrlForBlogs(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=blogs`)
}

async function generatePresignUrlForCompanyLogos(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=company-logos`)
}

async function generatePresignUrlForJD(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=jds`)
}

async function generatePresignUrl(containerName, filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=${containerName}`)
}

async function generatePresignUrlForProfiles(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=profiles`)
}

async function generatePresignUrlForIdealCandidateAudio(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=ideal-candidate-audios`)
}

async function generatePresignUrlForSessionFileUpload(sessionId, filename, token){
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sessions/${sessionId}/generatePresignUrl?filename=${filename}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

function reportInterviewIssue(token, data){
    return axiosInstance.post(`/report-interview-issue?token=${token}`, data)
}

function getHtmlFromText(data){
    return axiosInstance.post('/textToHtml', data)
}

function extractJDInfo(data){
    return axiosInstance.post('/extractJDInfo', data)
}

function evaluateJdSkills(data){
    return axiosInstance.post('/evaluate-jd-skills', data)
}

function extractBasicJdInfo(data){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    return axios.post(`${process.env.REACT_APP_ML_API_URL}/v1/job-description/parser`, data, {
        headers: {
            'Authorization': `Bearer ${accessToken.token}`
        }
    })
}

function getAnalytics(dateRange){
    return axiosInstance.get(`/hr/dashboard/analytics?dateRange=${getDateRange(dateRange)}`)
}

function generatePdfFromHtml(data){
    return axiosInstance.post('/htmlToPdf', data, {
        headers: {
            'Content-Type': 'text/html'
        }
    })
}

function getSessionInfo(token){
    return axiosInstance.get(`/getSessionInfo?token=${token}`)
}

function generateCodingQuestions(data){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    return axios.post(`${process.env.REACT_APP_GEN_QUESTIONS_API_URL}/v1/job-description/coding-questions`, data, {
        headers: {
            'Authorization': `Bearer ${accessToken.token}`
        }
    })
}

function uploadAnomalyInfo(token, data){
    return axios.post(`${process.env.REACT_APP_API_URL}/screening/session/anomaly?token=${token}`, data)
}

function getSpeechToken(token){
    return axiosInstance.get(`/get-speech-token?token=${token}`)
}


function getAzureToken(token){
    return axiosInstance.get(`/getAzureToken?token=${token}`)
}


function submitSessionFeedback(data){
    return axiosInstance.post('/submitSessionFeedback', data)
}

function submitTrialRequest(data){
    return axiosInstance.post('/trials', data)
}

function submitEnquiryRequest(data){
    return axiosInstance.post('/enquiries', data)
}


const CommonService = {
    getAnalytics,
    getAzureToken,
    extractJDInfo,
    getSessionInfo,
    getSpeechToken,
    getHtmlFromText,
    uploadAnomalyInfo,
    submitEnquiryRequest,
    submitTrialRequest,
    extractBasicJdInfo, 
    evaluateJdSkills,
    generatePresignUrl,
    reportInterviewIssue,
    generatePdfFromHtml,
    submitSessionFeedback,
    generatePresignUrlForJD,
    generateCodingQuestions,
    generatePresignUrlForProfiles,
    generatePresignUrlForResume,
    generatePresignUrlForBlogs,
    generatePresignUrlForCompanyLogos,
    generatePresignUrlForIdealCandidateAudio,
    generatePresignUrlForSessionFileUpload
}

export default CommonService;