import { Outlet } from "react-router-dom";

export default function EmailTemplateManagementHome(){
    return (
        <div className="w-full h-screen flex overflow-hidden">
            <div className="bg-[#ECF5FA] flex-col h-full overflow-hidden max-sm:!w-full w-full justify-center items-center flex">
                <div className="overflow-y-scroll h-full w-full flex justify-center">
                    <div className="h-full w-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}