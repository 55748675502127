import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import { useToast } from "../../contextProviders/ToastContext";

export default function RejectCandidatesPopup({ hide, rejectCandidates }) {
    const [rejectionReason, setRejectionReason] = useState('');
    const { showErrorMessage } = useToast();

    const handleSubmit = () => {
        if (!rejectionReason.trim()) {
            showErrorMessage('Rejection reason is required'); // Set error message
            return; // Stop submission
        }
        try {
             rejectCandidates(rejectionReason);
            hide();
        } catch (error) {
            console.error("Failed to reject resumes:", error);
        }
    };

    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center p-4 bg-black bg-opacity-50">
            <div className="w-full max-w-lg bg-white rounded-lg shadow overflow-hidden">
                <div className="p-6 flex flex-col gap-5">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="font-semibold text-primary text-xl">
                            Reject resumes
                        </h1>
                        <MdClear
                            size={20}
                            className="text-brownGray cursor-pointer"
                            onClick={hide}
                        />
                    </div>
                  
                    <InputTextarea 
                        placeholder="Reject reason"
                        className="p-2 w-full border-[.3px] border-gray text-sm"
                        rows={6}
                        cols={6}
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                    ></InputTextarea>
                    <div className="flex justify-center">
                        {/* <button
                            className="px-4 py-2 text-blue text-sm border-blue border-[1px] rounded-md"
                            onClick={hide}
                        >
                            Cancel
                        </button> */}
                        <button
                            className={`px-4 py-2 text-sm rounded-md bg-blue text-white
                            ${!rejectionReason ? 'opacity-50' : 'opacity-100' }`} 
                            onClick={handleSubmit}
                            disabled={!rejectionReason}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
