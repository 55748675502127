import axiosInstance from ".";

function getTemplates(page=1, limit=10){
    return axiosInstance.get(`/email-templates`)
    // return axiosInstance.get(`/email-templates?page=${page}&limit=${limit}`)
}

function getTemplate(templateId){
    return axiosInstance.get(`/email-templates/${templateId}`)
}

function createTemplate(data){
    return axiosInstance.post('/email-templates', data)
}

function updateTemplate(templateId, data){
    return axiosInstance.put(`/email-templates/${templateId}`, data)
}

const EmailTemplateService = {
    getTemplate,
    getTemplates,
    updateTemplate,
    createTemplate
}

export default EmailTemplateService;